<template>
<div class="expect_card">
  <el-cascader style="width:100%"
               v-if="Object.keys(allPositionList).length > 0"
               :show-all-levels = "false"
               :props="positionProp"
               v-model="position"/>

  <div class="button_group">
    <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
    <el-button type="primary" round @click="op('save')">保存</el-button>
  </div>
</div>
</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
import {getPositionList,updateExpectPost} from "@/api/user/user";
import {getParentIdArr} from "@/util/util";

export default {
  name: "ExpectedCard",
  props:{
    list:{
      type:Array,
      default(){
        return [];
      }
    }
  },
  data(){
    let that = this;
    return {
      position:[],
      alreadyInitParentIds:[],
      positionProp:{
        multiple:true,
        lazy:true,
        checkStrictly:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          // console.log(JSON.parse(JSON.stringify(node.data)));
          if(!node.data.leaf && that.alreadyInitParentIds.includes(node.data.value)){
            //父节点已经初始化
            resolve(nodes);
            return;
          }
          that.alreadyInitParentIds.push(node.data.value);
          if(level === 0){
            that.allPositionList.map(v=>{
              if(v.parentId === 0 || v.parentId === '0'){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:false
                });
              }
            });
            resolve(nodes);
          }else{
            that.allPositionList.map(v=>{
              if(v.parentId === node.data.value){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:true
                });
              }
            });
            resolve(nodes);
          }
        }
      },
      allPositionList:[]
    }
  },
  methods:{
    op(act){
      if(act === "cancel"){
        this.$emit("sub_event",{
          act:"expected_edit_cancel"
        });
      }else{
        let postList = [];
        for(let i in this.position){
          postList.push({
            postClassificationId:this.position[i][this.position[i].length - 1]
          });
        }
        if(postList.length > 0){
          updateExpectPost(postList).then(res => {
            if(res.code === 0){
              this.$emit("sub_event",{act:"expected_edit_sure"});
            }else{
              this.$message({
                type:"error",
                message:res.msg
              });
              this.op("cancel")
            }
          });
        }
      }
    }
  },
  watch:{
    position:{
      deep:true,
      handler(v,o){
        if(v.length > 3){
          this.$message({
            type:"warning",
            message:"最多选择3项"
          });
          this.$nextTick(()=>{
            this.position = o;
          });
        }
      }
    }
  },
  mounted(){
    getPositionList().then(res => {
      this.allPositionList = res.data;
      this.loadComplete=true;

      for(let i in this.list){
        this.position.push(getParentIdArr(this.list[i].postClassificationId,this.allPositionList));
      }
      console.log(this.position);
    });
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style scoped>

</style>
