<template>
  <div class="school_card">
    <el-form
        label-position="top"
    >
      <div style=" display: flex;
    flex-wrap: wrap;">
        <el-form-item label="学校名称">
          <el-input placeholder="请输入学校名称" v-model="school.schoolName"/>
        </el-form-item>

        <el-form-item label="学制">
          <el-select v-model="school.xuezhi" class="m-2" placeholder="请选择学制" size="large">
            <el-option
                v-for="item in years"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="学历">
          <el-select v-model="school.education" class="m-2" placeholder="学历" size="large">
            <el-option
                v-for="item in degree"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-input placeholder="请输入专业" v-model="school.specialty"/>
        </el-form-item>
        <el-form-item label="在校时间" class="at_position">
          <el-date-picker
              v-model="school.startDate"
              format="YYYY年M月"
              value-format="YYYY-MM"
              type="month"
              placeholder="开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="&nbsp;">
          <el-date-picker
              v-model="school.endDate"
              format="YYYY年M月"
              value-format="YYYY-MM"
              type="month"
              placeholder="毕业时间"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="在校经历" style="width:100%">
        <el-input
            v-model="school.content"
            rows="4"
            :autosize="{minRows:3}"
            type="textarea"
            placeholder="输入在校经历"
        />
      </el-form-item>
    </el-form>
    <div class="button_group">
      <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
      <el-button type="primary" round @click="op('save')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
export default {
  name: "schoolCard",
  props:["item"],
  data(){
    return {
      userInfo:{
        name:"",
      },
      school:{
        content: "",
        education: "",
        endDate: "",
        schoolName: "",
        specialty: "",
        startDate: "",
        xuezhi: ""
      },
      years:[],
      degree:[]
    }
  },
  methods:{
    op(act){
      if("cancel"===act){
        this.$emit("sub_event",{
          act:"school_edit_cancel"
        });
      }else{
        if(this.school.schoolName.trim() === ""){
          this.$message.error("学校名称不能为空");
          return ;
        }
        if(this.school.xuezhi === ""){
          this.$message.error("请选择学制");
          return ;
        }
        if(this.school.education === ""){
          this.$message.error("请选择学历");
          return ;
        }
        if(!this.school.specialty || this.school.specialty.trim() === ""){
          this.$message.error("请填写专业");
          return ;
        }
        if(this.school.startDate === "" || this.school.endDate === ""){
          this.$message.error("请选择在校时间");
          return ;
        }
        if(this.school.content.trim() === ""){
          this.$message.error("请填写在校经历");
          return ;
        }
        this.$emit("sub_event",{act:"school_edit_sure",school:this.school});
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
  mounted(){
    if(this.item && Object.keys(this.item).length >0 ){
      this.school = JSON.parse(JSON.stringify(this.item))
    }
    this.years = this.userStore.xuezhi;
    this.degree = this.userStore.degree;
  }
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.school_card{
  padding-top:.1px;
  .at_position{
    position:relative;
    &::after{
      position:absolute;
      content:" ~ ";
      top:40px;
      right: -25px;
      color: #a1a1a1;
    }
  }
  .el-form-item{
    margin-right:40px;
    width:270px;
    .el-select{
      width:100%;
    }
    &:nth-child(3n){
      margin-right:0;
    }
  }
  .el-form--label-top .el-form-item__label{
    padding-bottom:0;
  }
}
</style>
