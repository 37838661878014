import { defineStore } from 'pinia';
import {getProvinceList,getCityList} from "@/api/user/area";

export const useAreaStore = defineStore({
    id:"area",
    state:() => {
        return {
            provinceList:[],
            cityList:[]
        }
    },
    actions:{
        getProvinceName(code){
            let res = "";
            for(let i in this.provinceList){
                if(this.provinceList[i].provinceCode === code){
                    res = this.provinceList[i].provinceName;
                    break;
                }
            }
            return res;
        },
        getCityName(code){
            let res = "";
            for(let i in this.cityList){
                if(this.cityList[i].cityCode === code){
                    res = this.cityList[i].cityName;
                    break;
                }
            }
            return res;
        },
        getProvinceList(){
            return new Promise((resolve) => {
                getProvinceList().then(res => {
                    this.provinceList = res.data;
                    resolve(res.data);
                })
            })

        },
        getCityList(data){
            return new Promise((resolve) => {
                getCityList(data).then(res => {
                    this.cityList = res.data;
                    resolve(res.data);
                });
            })

        }
    }
})
