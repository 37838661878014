<template>
<div class="view_work_card">
  <div class="view_work_hover" v-if="edit === 0">
    <div class="view_work">
      <div class="work_top">
        <div class="work_title">{{item.companyName}}&nbsp;&nbsp; <div class="work_date">{{transDate(item.startDate)}} - {{transDate(item.endDate)}}</div></div>
        <div class="op"><span @click="op('del')">删除</span>/<span @click="op('edit')">编辑</span></div>
      </div>
      <div class="work_job">{{item.department}}<span style="font-size:10px;margin:0 3px;">•</span>{{item.post}}</div>
      <div class="content_txt">{{item.content}}</div>
    </div>
  </div>
  <work-card v-else @sub_event="subEvent" :item="item"></work-card>
</div>
</template>

<script>
import WorkCard from "@/pages/resumeCom/workCard";
import moment from "moment";
import {updateUserWorkExperience} from "@/api/user/user";
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
import {transYearMonth} from "@/util/util";

export default {
  name: "viewWorkCard",
  props:["item","index"],
  components: {WorkCard},
  data(){
    return {
      edit:0,
      work:JSON.parse(JSON.stringify(this.item))
    }
  },
  methods:{
    op(act){
      if(act === 'edit'){
        this.edit = 1;
      }else if(act === 'del'){
        this.$confirm(
            '确定删除该工作经历？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
          .then(() => {
            // this.$emit("sub_event",{act:"work_delete",index:this.index});
            this.opItem("delete");
          })
      }
    },
    opItem(act,param={}){
      let WorkExperience = JSON.parse(JSON.stringify(this.userStore.userInfo.userWorkExperienceList));
      if(act === "update"){
        WorkExperience[this.index]=JSON.parse(JSON.stringify(param.work));
      }else if(act === "delete"){
        WorkExperience.splice(this.index,1);
      }
      // for(let i in WorkExperience){
      //   if(WorkExperience[i].startDate.indexOf('年')===-1){
      //     WorkExperience[i].startDate = moment(WorkExperience[i].startDate).format("YYYY年M月");
      //     WorkExperience[i].endDate = moment(WorkExperience[i].endDate).format("YYYY年M月");
      //   }
      // }
      WorkExperience = transYearMonth(WorkExperience);
      updateUserWorkExperience(WorkExperience).then(res => {
        if(res.code === 0){
          this.edit = 0;
          this.userStore.$patch((state) => {
            state.userInfo.userWorkExperienceList = JSON.parse(JSON.stringify(WorkExperience));
          });
        }else{
          this.$message.error(res.msg);
        }
      });
    },
    transDate(d){
      if(d.indexOf("年") === -1){
        return moment(d).format("YYYY/M");
      }else{
        return d.replace('年','/').replace('月','');
      }

    },
    subEvent(param){
      if("work_edit_cancel" === param.act){
        this.edit = 0;
      }else if("work_edit_sure" === param.act){
        this.opItem("update",param);
        // this.edit = 0;
        // param.index = this.index;
        // this.$emit("sub_event",param);
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  }
}
</script>

<style lang="less" scoped>
.view_work_hover{
  margin:0 -20px;
  border-radius:10px;
  overflow: hidden;
  .view_work{
    margin-top:20px;
    margin-bottom:20px;
    padding:0 20px;
  }
}
.view_work_card:hover .view_work_hover{

  background-color:#f3f2f0;
}
.work_top{
  display:flex;
  justify-content: space-between;
  .work_title{
    font-size: 15px;
    color: #1a1a1a;
    font-weight: bold;
    display:flex;
    align-items: flex-end;
    .work_date{
      font-size: 12px;
      color: #666666;
      font-weight: normal;
      margin-left:5px;
    }
  }
  .op{
    font-size: 15px;
    color:#929292;
    span{
      color: #00b0b1;
      margin:5px;
      cursor:pointer;
    }
  }
}
.work_job{
  font-size: 13px;
  color: #1a1a1a;
  margin-top:7px;
}
.content_txt{
  margin-top:15px;
}
</style>
