<template>
  <div class="text_edit">
    <el-input
        v-model="textarea"
        rows="4"
        :autosize="{minRows:3}"
        type="textarea"
        placeholder="请输入个人优势"
    />
    <div class="button_group">
      <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
      <el-button type="primary" round @click="op('save')">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    frm:{
      default:"",
      type:String
    },
    txt:{
      default:"",
      type:String
    }
  },
name: "textEdit",
  data(){
    return {
      textarea:this.txt
    }
  },
  methods:{
    op(act){
      if(act === "cancel"){
        this.$emit("sub_event",{act:"text_edit_cancel",frm:this.frm});
      }else if(act === "save"){
        this.$emit("sub_event",{act:"text_edit_sure",txt:this.textarea,frm:this.frm});
      }
    }
  }
}
</script>

<style scoped>

</style>
