<template>
  <div class="page_resume_edit" v-if="Object.keys(userStore.userInfo).length>0">
    <div class="resume_body content">
      <div class="resume_left">
        <div class="resume_title">简历目录</div>
        <div class="resume_item" @click="jumpAnchor('person')">个人信息</div>
        <a class="resume_item" @click="jumpAnchor('talent')">个人优势</a>
        <div class="resume_item" @click="jumpAnchor('expected')">期望职位</div>
        <div class="resume_item" @click="jumpAnchor('work')">工作经历</div>
        <div class="resume_item" @click="jumpAnchor('project')">项目经历</div>
        <div class="resume_item" @click="jumpAnchor('education')">教育经历</div>
        <div class="resume_item" @click="jumpAnchor('cert')">资质证书</div>
        <div class="resume_item" @click="jumpAnchor('resume')">附件简历</div>
        <div class="resume_item"  @click="jumpAnchor('lobby')">兴趣爱好</div>
        <div class="resume_title" @click="showPage='collect'" style="cursor:pointer">我的收藏</div>
      </div>
      <div class="resume_right">
        <div class="edit_wrapper" :class="{view_wrapper:editPerson === 0}" id="person">
          <div class="edit_wrapper_inner">
            <person-card v-if="editPerson === 1" @sub_event="subEvent"></person-card>
            <view-person-card v-else></view-person-card>
            <div class="admin_edit" v-if="isAdmin" @click="editItem('upload')" style="right:100px;">上传简历</div>
            <div class="admin_edit" v-if="isAdmin" @click="editItem('person')">编辑</div>
          </div>
        </div>
        <div  v-if="showPage === ''">
          <div class="edit_wrapper" :class="{view_wrapper:editTalent === 0}" id="talent">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item" v-if="editTalent === 1">
                <div class="edit_title view_title">个人优势</div>
                <textEdit :txt="userStore.userInfo.advantage" frm="talent" @sub_event="subEvent"></textEdit>
              </div>
              <div class="person_edit_item" v-else>
                <div class="edit_title view_title">个人优势</div>
                <div class="content_txt" v-html="userStore.userInfo.advantage">
                </div>
              </div>
              <div class="admin_edit" v-if="isAdmin" @click="editItem('talent')">
                编辑
              </div>
            </div>
          </div>
          <div class="edit_wrapper view_wrapper" id="expected">
            <div class="edit_wrapper_inner">
              <div class="edit_title view_title">期望职位</div>
              <expected-card v-if="editExpected === 1"  :list = "userStore.userInfo.expectPostList" @sub_event="subEvent"></expected-card>
              <div class="expected" v-else >
                <el-tag type="success" style="margin-right:10px;" v-for="item in userStore.userInfo.expectPostList" :key="item.postClassificationId">{{item.postClassificationName}}</el-tag>
              </div>
              <div class="admin_edit" if="isAdmin" @click="editItem('expected')">编辑</div>
            </div>
          </div>
          <div class="edit_wrapper view_wrapper"  id="work">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item">
                <div class="edit_title view_title">工作经历</div>
                <view-work-card v-for="(item,index) in userStore.userInfo.userWorkExperienceList"
                                @sub_event="subEvent"
                                :key="index"
                                :item="item"
                                :index="index"></view-work-card>
                <div id="add_work"></div>
                <work-card v-if="addWork === 1" @sub_event="subEvent"></work-card>
              </div>
              <div class="admin_edit" v-if="isAdmin" @click="editItem('work')">
                <div class="icon_add">+</div>
              </div>
            </div>
          </div>
          <div class="edit_wrapper view_wrapper" id="project">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item">
                <div class="edit_title view_title">项目经历</div>
                <view-project-card v-for="(item,index) in userStore.userInfo.userProjectExperienceList"
                                   @sub_event="subEvent"
                                   :key="index"
                                   :item="item"
                                   :index="index"
                ></view-project-card>
                <div id="add_project"></div>
                <project-card v-if="addProject === 1" @sub_event="subEvent"></project-card>
              </div>
            </div>
            <div class="admin_edit" v-if="isAdmin" @click="editItem('project')">
              <div class="icon_add">+</div>
            </div>
          </div>
          <div class="edit_wrapper" :class="{view_wrapper:editPerson === 0}" id="education">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item">
                <div class="edit_title view_title">教育经历</div>
                <view-school-card v-for="(item,index) in userStore.userInfo.userEducationBackgroundList"
                                  @sub_event="subEvent"
                                  :key="index"
                                  :item="item"
                                  :index="index"
                ></view-school-card>
              </div>
              <school-card v-if="addSchool===1" @sub_event="subEvent"></school-card>
              <div class="admin_edit" v-if="isAdmin" @click="editItem('education')">
                <div class="icon_add">+</div>
              </div>
            </div>
          </div>

          <div class="edit_wrapper" :class="{view_wrapper:editPerson === 0}" id="cert">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item">
                <div class="edit_title view_title">资质证书</div>
                <view-cert-card v-for="(item,index) in userStore.userInfo.userCertificateList"
                                @sub_event="subEvent"
                                :key="index"
                                :title="item.certificateName"
                                :item="item"
                                :index="index"></view-cert-card>
              </div>
              <upload-bar title="证书名称" v-if="addCert === 1" @sub_event="subEvent"  upload-type="cert_image"></upload-bar>
              <div class="admin_edit" v-if="isAdmin" @click="editItem('cert')">
                <div class="icon_add">+</div>
              </div>
            </div>
          </div>

          <div class="edit_wrapper" :class="{view_wrapper:editPerson === 0}" id="resume">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item">
                <div class="edit_title view_title">附件简历</div>
                <div v-if="editResume === 0" style="display:flex;font-size:15px;align-items: center;cursor:pointer;" @click="downloadResume"  class="normal_black"><img src="../assets/img/icon_word.svg" style="width:40px;height:40px;margin-right:5px;"/> 个人简历 <span style="font-size:12px;margin-left:3px;color:#00cc99;">{{userStore.userInfo.user.resumeUrl?'[已上传]':'[未上传]'}}</span></div>
                <upload-bar v-else title="支持上传PDF格式，文件大小不超过20M" upload-type="resume" @sub_event="resumeEvent" :has-name="false" :ext="['pdf']"></upload-bar>
              </div>
              <div class="admin_edit" v-if="isAdmin" @click="editItem('resume')">
                编辑
              </div>
            </div>
          </div>

          <div class="edit_wrapper" :class="{view_wrapper:editPerson === 0}" id="lobby">
            <div class="edit_wrapper_inner">
              <div class="person_edit_item">
                <div class="edit_title view_title">兴趣爱好</div>
                <div class="content_txt" v-if="editHobby === 0">{{userStore.userInfo.hobbies}}</div>
                <text-edit v-else @sub_event="subEvent" :txt="userStore.userInfo.hobbies" frm="hobby"></text-edit>
              </div>
              <div class="admin_edit" v-if="isAdmin" @click="editItem('hobby')">
                编辑
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="showPage === 'collect'" class="job_list">
          <position-card
              v-for="item in collectList"
              :item="item"
              :key="item.companyPostId"
          ></position-card>
        </div>
      </div>
    </div>
    <div v-if="showPage === 'collect'" style="text-align: center;margin-top:20px;">
      <div style="display:inline-block;margin:0 auto;">
        <el-pagination
            v-model:current-page="param.pageNum"
            @current-page="pageChange"
            :page-size="param.pageSize" background layout="prev, pager, next" :total="total" />
      </div>
    </div>
    <upload-resume v-if="showUploadResume" :show="showUploadResume" @sub_event="subEvent"></upload-resume>
  </div>
</template>

<script>
import PersonCard from "@/pages/resumeCom/personCard";
import TextEdit from "@/pages/resumeCom/textEdit";
import WorkCard from "@/pages/resumeCom/workCard";
import ProjectCard from "@/pages/resumeCom/projectCard";
import SchoolCard from "@/pages/resumeCom/schoolCard";
import UploadBar from "@/pages/resumeCom/uploadBar";
import ViewPersonCard from "@/pages/resumeCom/viewPersonCard";
import ViewWorkCard from "@/pages/resumeCom/viewWorkCard";
import ViewProjectCard from "@/pages/resumeCom/viewProjectCard";
import ViewSchoolCard from "@/pages/resumeCom/viewSchoolCard";
import ViewCertCard from "@/pages/resumeCom/viewCertCard";
import ExpectedCard from "@/pages/resumeCom/expectedCard"
import PositionCard from "@/components/positionCard";
// import moment from "moment";
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import { transYearMonth } from "@/util/util";
import {
  updateAdvantage, updateHobbies, updateUserCertificate,
  updateUserEducationBackground,
  updateUserProjectExperience,
  updateUserWorkExperience,getCollection
} from "@/api/user/user";
import UploadResume from "@/pages/resumeCom/uploadResume";
export default {
name: "resumeEdit",
  components: {
    UploadResume,
    ViewCertCard,PositionCard,
    ViewSchoolCard,ViewProjectCard,ViewWorkCard, ViewPersonCard, SchoolCard, ProjectCard, WorkCard, PersonCard,TextEdit,UploadBar,ExpectedCard},
  data(){
    return {
      isAdmin:true,
      showPage:"",
      editPerson:0,
      editTalent:0,
      editWork:0,
      editResume:0,
      editHobby:0,
      editExpected:0,
      addWork:0,
      addSchool:0,
      addProject:0,
      addCert:0,
      param:{
        pageNum:1,
        pageSize:10
      },
      collectList:[],
      total:0,
      showUploadResume:false
    }
  },
  methods:{
    pageChange(v){
      this.param.pageNum = v;
      this.getCollection();
    },
    getCollection(){
      getCollection(this.param).then(res => {
        if(res.code === 0){
          this.total = res.data.total;
          this.collectList = res.data.rows;
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    },
    editItem(act){
      console.log(act);
      if(act === "person"){
        this.editPerson = 1;
      }else if(act === "talent"){
        this.editTalent = 1;
      }else if(act === "work"){
        this.addWork=1;
        this.jumpAnchor("add_work")
      }else if(act === "project"){
        this.addProject = 1;
        this.jumpAnchor("add_project")
      }else if(act === "education"){
        this.addSchool = 1;
      }else if(act === "cert"){
        this.addCert = 1;
      }else if(act === "resume"){
        this.editResume = 1;
      }else if(act === "hobby"){
        this.editHobby = 1;
      }else if(act === "expected"){
        this.editExpected = 1;
      }else if(act === "upload"){
        this.showUploadResume = true
      }
    },
    subEvent(param){
      if(param.act === "person_edit_cancel"){
        this.editPerson = 0;
      }else if(param.act === "text_edit_cancel"){
        if(param.frm === "talent"){
          this.editTalent = 0;
        }else if(param.frm === "hobby"){
          this.editHobby = 0;
        }
      }else if(param.act === "work_edit_cancel"){
        this.addWork = 0;
        this.jumpAnchor("work")
      }else if(param.act === "project_edit_cancel"){
        this.addProject = 0;
        this.jumpAnchor("project")
      }else if(param.act === "school_edit_cancel"){
        this.addSchool = 0;
      }else if(param.act === "expected_edit_cancel"){
        this.editExpected = 0;
      }else if(param.act === "expected_edit_sure"){
        this.userStore.getUserInfo();
        this.editExpected = 0;
      }else if(param.act === "text_edit_sure"){
        if(param.frm === "talent"){
          updateAdvantage({
            content:param.txt
          }).then(res => {
            if(res.code === 0){
              this.userStore.$patch((state)=>{
                state.userInfo.advantage = param.txt;
              });
            }else{
              this.$message.error(res.msg);
            }
          });
          this.editTalent = 0;
        }else if(param.frm === "hobby"){
          updateHobbies({
            content:param.txt
          }).then(res => {
            if(res.code === 0){
              this.userStore.$patch((state) => {
                state.userInfo.hobbies = param.txt
              });
            }else{
              this.$message.error(res.msg);
            }
          });
          this.editHobby = 0;
        }
      }else if(param.act === "work_edit_sure"){
        let WorkExperience = JSON.parse(JSON.stringify(this.userStore.userInfo.userWorkExperienceList));
        if(this.addWork=== 1){
          WorkExperience.push(JSON.parse(JSON.stringify(param.work)));
          WorkExperience = transYearMonth(WorkExperience);
          // for(let i in WorkExperience){
          //   if(WorkExperience[i].startDate.indexOf('年')===-1){
          //     WorkExperience[i].startDate = moment(WorkExperience[i].startDate).format("YYYY年M月");
          //     WorkExperience[i].endDate = moment(WorkExperience[i].endDate).format("YYYY年M月");
          //   }
          // }
          updateUserWorkExperience(WorkExperience).then(res => {
            if(res.code === 0){
              this.userStore.$patch((state) => {
                state.userInfo.userWorkExperienceList = JSON.parse(JSON.stringify(WorkExperience));
              });
              this.addWork = 0;
            }else{
              this.$message.error(res.msg);
            }
          });
        }
      }else if(param.act === "project_edit_sure"){
        let userProjectExperienceList = this.userStore.userInfo.userProjectExperienceList;
        if(this.addProject === 1){
          userProjectExperienceList.push(JSON.parse(JSON.stringify(param.project)));
        }
        userProjectExperienceList = transYearMonth(userProjectExperienceList);
        // for(let i in userProjectExperienceList){
        //   if(userProjectExperienceList[i].startDate.indexOf("年")===-1){
        //     userProjectExperienceList[i].startDate = moment(userProjectExperienceList[i].startDate).format("YYYY年M月");
        //     userProjectExperienceList[i].endDate = moment(userProjectExperienceList[i].endDate).format("YYYY年M月");
        //   }
        // }
        updateUserProjectExperience(userProjectExperienceList).then(
            res => {
              if(res.code === 0){
                this.userStore.$patch((state) => {
                  state.userInfo.userProjectExperienceList = userProjectExperienceList;
                });
                this.addProject = 0;
              }else{
                this.$message.error(res.msg);
              }
            }
        );
      }else if(param.act === "school_edit_sure"){
        let userEducationBackgroundList = this.userStore.userInfo.userEducationBackgroundList;
        if(this.addSchool === 1){
          userEducationBackgroundList.push(JSON.parse(JSON.stringify(param.school)));
        }
        userEducationBackgroundList = transYearMonth(userEducationBackgroundList);
        // for(let i in userEducationBackgroundList){
        //   if(userEducationBackgroundList[i].startDate.indexOf('年')===-1){
        //     userEducationBackgroundList[i].startDate = moment(userEducationBackgroundList[i].startDate).format("YYYY年M月");
        //     userEducationBackgroundList[i].endDate = moment(userEducationBackgroundList[i].endDate).format("YYYY年M月");
        //   }
        // }

        updateUserEducationBackground(userEducationBackgroundList).then(res => {
          if(res.code === 0){
            this.userStore.$patch((state) => {
              state.userInfo.userEducationBackgroundList = userEducationBackgroundList;
            });
            this.addSchool = 0;
          }else{
            this.$message.error(res.msg);
          }
        });
      }else if(param.act === "upload_success" || param.act === "cert_delete"){
        let userCertificateList = JSON.parse(JSON.stringify(this.userStore.userInfo.userCertificateList));
        if(this.addCert === 1){
          userCertificateList.push({
            certificateName:param.name,
            imageUrl:param.data
          });
          updateUserCertificate(userCertificateList).then(res => {
            if(res.code === 0){
              this.userStore.$patch((state) => {
                state.userInfo.userCertificateList = userCertificateList;
              });
            }
            this.addCert = 0;
          });
        }
      }else if(param.act === "close_upload_resume"){
        this.showUploadResume = false;
      }else if(param.act === "cancel_upload"){
        this.addCert = false;
      }
    },
    resumeEvent(param){
      if(param.act === "cancel_upload"){
        this.editResume = 0;
      }else if(param.act === "upload_success"){
        this.editResume = 0;
        this.userStore.getUserInfo();
      }
    },
    downloadResume(){
      let url = this.userStore.userInfo.user.resumeUrl;
      if(url && url!=''){
        window.open(url);
      }

    },
    jumpAnchor(which){
      if(this.showPage!==''){
        this.showPage = '';
        this.$nextTick(()=>{
          setTimeout(()=>{
            this.scrollAnchor(which);
          },300);
        });
      }else{
        this.scrollAnchor(which);
      }

    },
    scrollAnchor(which){
        let id = "#" + which; // 给id加上井号
        // 给对应dom滚动到对应锚点，使之出现在视图中
        document.querySelector(id).scrollIntoView({
          behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
          block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
          inline: "center", // 定义水平滚动方向的对齐
        });
    }
  },
  mounted(){
    this.userStore.getIndustryList();
    this.userStore.getPositionList();
    this.getCollection();
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
@import "../assets/css/variable";
  .resume_body{
    margin-top:20px;
    display:flex;
    justify-content: space-between;
    .job_list{
      background-color:#fff;
      padding: 0.1px 0 15px 0;
      .position_card{
        margin:15px 15px 0;
        border:4px solid #f3f2f0;
      }
    }
    .edit_wrapper{
      background-color:#fff;
      overflow:hidden;
      margin-bottom:10px;
      position:relative;
      &:last-child{
        margin-bottom:0;
      }
      .admin_edit{
        position:absolute;
        top:40px;right:50px;
        color:@blue_color;
        font-size:15px;
        cursor:pointer;
        .icon_add{
          width:25px;height:25px;border-radius: 25px;cursor:pointer;background-color:@blue_color;color:#fff;display:flex;justify-content: center;line-height:21px;font-size:30px;
        }
      }
      .edit_title{
        font-size: 19px;
        color: @blue_color;
        margin-bottom:15px;
      }
      .view_title{
        font-size:22px;
        color:@font_color;
        font-weight: bold;
        margin-bottom:30px;
      }
      .content_txt{
        font-size: 15px;
        line-height: 23px;
        color: #1a1a1a;
      }
    }
    .edit_wrapper_inner{
         margin:50px;
    }
    .view_wrapper{
      .edit_wrapper_inner{
        margin:40px;
      }
    }

    .resume_left{
      background-color:#fff;
      overflow: hidden;
      padding-bottom:20px;
    }
    .resume_right{
      overflow: hidden;
    }
    .resume_title{
      margin-top:20px;
      font-size: 16px;
      line-height: 63px;
      color: #1a1a1a;
      font-weight: bold;
      text-align: center;
    }
    .resume_left{
      width:190px;
      .resume_item{
        display:block;
        text-decoration: none;
        height:40px;
        line-height:40px;
        font-size:14px;
        color:#666666;
        text-align: center;
        cursor:pointer;
        &:hover{
          background-color:@blue_color;
          color:#fff;
        }
      }
      .resume_item_cur{
        background-color:@blue_color;
        color:#fff;
      }
    }
    .resume_right{
      width:990px;
    }
  }
</style>
