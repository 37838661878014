<template>
  <div class="project_card">
    <el-form
        label-position="top"
    >
      <div style="display:flex;justify-content: space-between">
        <el-form-item label="项目名称" style="width:565px;">
          <el-input v-model="project.projectName" placeholder="项目名称"/>
        </el-form-item>
        <el-form-item label="项目角色" style="width:260px;">
          <el-input v-model="project.projectRole" placeholder="项目角色"/>
        </el-form-item>
      </div>

      <div style="display:flex;justify-content: space-between">
        <el-form-item label="项目时间" class="at_position" style="width:413px;">
          <el-date-picker
              v-model="project.startDate"
              format="YYYY年M月"
              value-format="YYYY-MM"
              type="month"
              placeholder="开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="&nbsp;" style="width:413px;">
          <el-date-picker
              v-model="project.endDate"
              format="YYYY年M月"
              value-format="YYYY-MM"
              type="month"
              placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>
      </div>

      <el-form-item label="项目内容" style="width:100%">
        <el-input
            v-model="project.content"
            rows="4"
            :autosize="{minRows:3}"
            type="textarea"
            placeholder="输入项目内容"
        />
      </el-form-item>
    </el-form>

    <div class="button_group">
      <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
      <el-button type="primary" round @click="op('save')">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectCard",
  props:["item"],
  data(){
    return {
      project:{
        content: "",
        endDate: "",
        projectName: "",
        projectRole: "",
        startDate: ""
      }
    }
  },
  methods:{
    op(act){
      if(act === "cancel"){
        this.$emit("sub_event",{
          act:"project_edit_cancel"
        });
      }else{
        if(this.project.projectName.trim() === ""){
          this.$message.error("项目名称不能为空");
          return ;
        }
        if(this.project.projectRole.trim() === ""){
          this.$message.error("请填写项目角色");
          return ;
        }
        if(this.project.startDate === "" || this.project.endDate === ""){
          this.$message.error("请选择项目时间");
          return ;
        }
        if(this.project.content.trim() === ""){
          this.$message.error("请填写项目内容");
          return ;
        }
        this.$emit("sub_event",{act:"project_edit_sure",project:this.project});
      }
    }
  },
  components:{
  },
  mounted(){
    if(this.item && Object.keys(this.item).length >0 ){
      this.project = JSON.parse(JSON.stringify(this.item))
    }
  }
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.project_card{
  padding-top:.1px;
  .at_position{
    position:relative;
    &::after{
      position:absolute;
      content:" ~ ";
      top:40px;
      right: -25px;
      color: #a1a1a1;
    }
  }
  .el-form-item{
    margin-right:40px;
    width:270px;
    .el-select{
      width:100%;
    }
    &:nth-child(3n){
      margin-right:0;
    }
  }
  .el-form--label-top .el-form-item__label{
    padding-bottom:0;
  }
}
</style>
