<template>
  <div class="upload_resume">
    <el-dialog
        width="50%"
        title="上传简历"
        class="upload_resume_dialog"
        @close="closeDialog"
        append-to-body
        v-model="showResume"
        v-loading="loading"
    >
      <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          :on-success="handleAvatarSuccess"
          :http-request="handleResumeUpload"
      >
        <i class="el-icon-plus avatar-uploader-icon"> + </i>
      </el-upload>
<!--          <div style="margin:10px auto;">我的简历 &nbsp;&nbsp; <i class="el-icon-close"></i></div>-->
      <el-button type="primary" @click="download" style="margin-top:10px;">模版下载</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { uploadResumeWord } from "@/api/user/user";

export default {
  name: "UploadResume",
  props:["show"],
  data(){
    return {
      showResume:this.show,
      loading:false
    }
  },
  methods:{
    handleAvatarSuccess(res, file) {
    },
    handleResumeUpload(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      uploadResumeWord(formData).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          this.$message.error(res.msg);
        }else{
          this.$message({
            type:"success",
            message:"上传成功"
          })
        }
        this.closeDialog();
      });
    },
    // beforeAvatarUpload(file) {
    //   // const isJPG = file.type === 'image/jpeg';
    //   // const isLt2M = file.size / 1024 / 1024 < 2;
    //   //
    //   // if (!isJPG) {
    //   //   this.$message.error('上传头像图片只能是 JPG 格式!');
    //   // }
    //   // if (!isLt2M) {
    //   //   this.$message.error('上传头像图片大小不能超过 2MB!');
    //   // }
    //   // return isJPG && isLt2M;
    //
    // },
    closeDialog(){
      this.$emit("sub_event",{act:"close_upload_resume"});
    },
    download(){
      // window.location.href = "assets/resume_template.docx"
      window.location.href = "//jobtest.eetalent.com.cn/job/api/user/downloadResumeTemplate";
    }
  }
}
</script>

<style lang="less">
.upload_resume_dialog .el-dialog__body{
  text-align: center;
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload__input {
    display: none;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
