<template>
<div class="person_card" v-loading="loading">
  <div class="photo_edit">
    <el-upload
        class="avatar-uploader"
        action=""
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :http-request="handleAvatarUpload"
        :before-upload="beforeAvatarUpload"
    >
      <div class="upload_photo"><img :src="userInfo.avatarUrl"/>上传头像</div>
    </el-upload>
  </div>
  <el-form
      label-position="top"
  >
    <el-form-item label="姓名">
      <el-input v-model="userInfo.realName" placeholder="请输入姓名"/>
    </el-form-item>

    <el-form-item label="当前状态">
      <el-select v-model="userInfo.workStatus" class="m-2" placeholder="选择当前工作状态" size="large">
        <el-option
            v-for="item in jobStatus"
            :key="item.id"
            :label="item.label"
            :value="item.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="出生日期">
      <el-date-picker
          v-model="userInfo.birthDate"
          format="YYYY年M月"
          type="month"
          placeholder="出生日期"
      ></el-date-picker>

    </el-form-item>
    <el-form-item label="电话">
      <el-input v-model="userInfo.mobile" placeholder="请输入电话"/>
    </el-form-item>
    <el-form-item label="性别">
      <el-select v-model="userInfo.sex" class="m-2" placeholder="性别" size="large">
        <el-option
            v-for="(item,index) in sexList"
            :key="item.value"
            :label="item.label"
            :value="index"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="邮箱">
      <el-input v-model="userInfo.email" placeholder="请输入邮箱"/>
    </el-form-item>
    <el-form-item label="参加工作时间">
      <el-date-picker
          v-model="userInfo.workDate"
          format="YYYY年M月"
          type="month"
          placeholder="参加工作时间"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="微信">
      <el-input v-model="userInfo.wechat" placeholder="微信"/>
    </el-form-item>
    <el-form-item label="所在城市">
      <el-cascader
          style="width:100%;"
          :props="areaProp" v-model="cityCode" :show-all-levels="false"></el-cascader>
<!--      <el-input placeholder="所在城市">-->
<!--        <template #suffix>-->
<!--          <img src="../../assets/img/icon_local.png" style="width:13px;"/>-->
<!--        </template>-->
<!--      </el-input>-->
    </el-form-item>
  </el-form>
  <div class="button_group">
    <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
    <el-button type="primary" round @click="op('save')">保存</el-button>
  </div>
</div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import { useAreaStore} from "@/store/user/area";
import { updateUser,updateUserWorkStatus,uploadAvatar } from "@/api/user/user";
import moment from "moment";
export default {
  name: "personCard",
  data(){
    let that = this;
    return {
      loading:false,
      userInfo:{},
      jobStatus:[],
      sexList:[],
      cityCode:[],
      // areaProp:{
      //   lazy: true,
      //   lazyLoad(node, resolve) {
      //     const { level } = node
      //     let nodes = [];
      //     nodes = [12,34,56,78,9].map(() => ({
      //       value: ++id,
      //       label: `Option - ${id}`,
      //       leaf: level >= 2,
      //     }))
      //     resolve(nodes)
      //   }
      // },
      areaProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            that.areaStore.getProvinceList().then(list => {
              nodes = list.map(v => ({
                label:v.provinceName,
                value:v.provinceCode,
                leaf:false
              }));
              resolve(nodes);
            });
          }else if(level === 1){
            that.areaStore.getCityList({provinceCode:node.value}).then(list => {
              nodes = list.map(v => ({
                label:v.cityName,
                value:v.cityCode,
                leaf:true
              }));
              resolve(nodes);
           });
          }
        }
      }
    }
  },
  methods:{
    handleAvatarSuccess(){

    },
    beforeAvatarUpload(){

    },
    handleAvatarUpload(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      uploadAvatar(formData).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          this.$message.error(res.msg);
        }else{
          this.$message({
            type:"success",
            message:"上传成功"
          })

          this.userStore.getUserInfo();
          setTimeout(()=>{
            this.userInfo.avatarUrl = this.userStore.userInfo.user.avatarUrl;
          },300)
        }
      });
    },
    op(act){
      if(act === "cancel"){
        this.$emit("sub_event",{act:"person_edit_cancel"});
      }else if(act === "save"){
        if(this.cityCode.length === 0){
          this.$message({
            type:"error",
            message:"请选择所在城市"
          });
          return ;
        }
        if(this.userInfo.birthDate && this.userInfo.birthDate!==''){
          // this.userInfo.age = moment().year() - moment(this.userInfo.birthDate).year();
          // if(this.userInfo.age < 1){
          //   this.userInfo.age = 1;
          // }
          this.userInfo.age = moment().diff(this.userInfo.birthDate, 'years');
        }
        this.userInfo.cityCode = this.cityCode[1];
        this.userInfo.provinceCode = this.cityCode[0];
        this.userInfo.provinceName = this.areaStore.getProvinceName(this.cityCode[0]);
        this.userInfo.cityName = this.areaStore.getCityName(this.cityCode[1]);
        updateUserWorkStatus({
          workStatus:this.userInfo.workStatus
        }).then((res)=>{
          if(res.code !== 0){
            this.$message.error(res.msg);
            return ;
          }
          updateUser(this.userInfo).then(res => {
            if(res.code === 0){
              this.$message({
                type:"success",
                message:"保存成功"
              });
              this.userStore.$patch((state) => {
                Object.assign(state.userInfo.user,this.userInfo);
              });
              this.op("cancel");
            }else{
              this.$message.error(res.msg);
            }
          });
        });

      }
    }
  },
  computed:{
    ...mapStores(useUserStore,useAreaStore)
  },
  components:{
  },
  mounted(){
    this.userInfo = JSON.parse(JSON.stringify(this.userStore.userInfo.user));
    this.jobStatus = this.userStore.jobStatus;
    this.sexList = this.userStore.sexList;
    this.cityCode = this.userInfo.cityCode ? [this.userInfo.cityCode.substr(0,2)+"0000",this.userInfo.cityCode]:[];
  }
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.person_card{
  padding-top:.1px;
  .el-form{
    display: flex;
    flex-wrap: wrap;
  }
  .el-form-item{
    margin-right:40px;
    width:270px;
    .el-select{
      width:100%;
    }
    &:nth-child(3n){
      margin-right:0;
    }
  }
  .el-form--label-top .el-form-item__label{
    padding-bottom:0;
  }
}
  .upload_photo{
    display:flex;
    align-items: center;
    font-size: 18px;
    color:@blue_color;
    cursor:pointer;
    img{
      width:60px;
      height:60px;
      margin-right:10px;
    }
  }
</style>
