<template>
  <div class="view_school_card">
    <div class="view_work_hover" v-if="edit === 0">
      <div class="view_work">
        <div class="work_top">
<!--          <div class="photo">-->
<!--            <img src="../../assets/img/test_photo.svg"/>-->
<!--          </div>-->
          <div>
            <div class="work_title">{{item.schoolName}} &nbsp;&nbsp;<div class="work_date">{{transDate(item.startDate)}} - {{transDate(item.endDate)}}</div></div>
            <div class="work_job">{{transEducation(item.education)}} | {{item.specialty}}</div>
          </div>

        </div>
        <div class="op"><span @click="op('del')">删除</span>/<span @click="op('edit')">编辑</span></div>
      </div>
    </div>
    <school-card v-else @sub_event="subEvent" :item="item"></school-card>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
import SchoolCard from "@/pages/resumeCom/schoolCard";
import moment from "moment";
import {updateUserEducationBackground} from "@/api/user/user";
import {transYearMonth} from "@/util/util";

export default {
  name: "viewProjectCard",
  props:["item","index"],
  components: {SchoolCard},
  data(){
    return {
      edit:0
    }
  },
  methods:{
    transDate(d){
      if(d.indexOf("年") === -1){
        return moment(d).format("YYYY/M");
      }else{
        return d.replace('年','/').replace('月','');
      }

    },
    transEducation(edu){
      let res = ""
      let degree = this.userStore.degree;
      for(let i in degree){
        if(degree[i].value === edu){
          res = degree[i].label;
          break;
        }
      }
      return res;
    },
    opItem(act,param={}){
      let userEducationBackgroundList = this.userStore.userInfo.userEducationBackgroundList;
      if(act === "update"){
        userEducationBackgroundList[this.index] = JSON.parse(JSON.stringify(param.school));
      }else if(act === "delete"){
        userEducationBackgroundList.splice(this.index,1);
      }
      // for(let i in userEducationBackgroundList){
      //   if(userEducationBackgroundList[i].startDate.indexOf('年')===-1){
      //     userEducationBackgroundList[i].startDate = moment(userEducationBackgroundList[i].startDate).format("YYYY年M月");
      //     userEducationBackgroundList[i].endDate = moment(userEducationBackgroundList[i].endDate).format("YYYY年M月");
      //   }
      // }
      userEducationBackgroundList = transYearMonth(userEducationBackgroundList);
      updateUserEducationBackground(userEducationBackgroundList).then(res => {
        if(res.code === 0){
          this.userStore.$patch((state) => {
            state.userInfo.userEducationBackgroundList = userEducationBackgroundList;
          });
          this.edit = 0;
        }else{
          this.$message.error(res.msg);
        }
      });
    },
    op(act){
      if(act === 'edit'){
        this.edit = 1;
      }else if(act === 'del'){
        this.$confirm(
            '确定删除该学校经历？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              // this.$emit("sub_event",{act:"school_delete",index:this.index});
              this.opItem("delete");
            })
      }
    },
    subEvent(param){
      if("school_edit_cancel" === param.act){
        this.edit = 0;
      }else if("school_edit_sure" === param.act){
        // this.edit = 0;
        // param.index = this.index;
        // this.$emit("sub_event",param);
        this.opItem("update",param);
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  }
}
</script>

<style lang="less" scoped>
.view_work_hover{
  margin:0 -20px;
  border-radius:10px;
  overflow: hidden;
  .view_work{
    margin-top:20px;
    margin-bottom:20px;
    padding:0 20px;
    display:flex;
    justify-content: space-between;
  }
}
.view_school_card:hover .view_work_hover{

  background-color:#f3f2f0;
}
.work_top{
  display:flex;
  justify-content: space-between;
  .work_title{
    font-size: 15px;
    color: #1a1a1a;
    font-weight: bold;
    display:flex;
    align-items: flex-end;
    img{
      width:60px;
      height:60px;
    }
    .work_date{
      font-size: 12px;
      color: #666666;
      font-weight: normal;
      margin-left:5px;
    }
  }
  .work_job{
    font-size: 13px;
    color: #617185;
  }
}
.op{
  font-size: 15px;
  color:#929292;
  span{
    color: #00b0b1;
    margin:5px;
    cursor:pointer;
  }
}
</style>
