<template>
  <div class="view_person_card">
    <div class="person_info">
      <div class="photo">
        <img :src="userInfo.avatarUrl"/>
      </div>
      <div class="person_detail">
          <div class="name">{{userInfo.realName}}</div>
        <div class="person_item">
          <div class="item" v-if="userInfo.age"><img src="../../assets/img/icon_age.png" style="width:11px;height:11px;"/>{{userInfo.age}}</div>
          <div class="item"><img src="../../assets/img/icon_work_years.png" style="width:10px;height:10px;"/>工作{{userInfo.workYear}}年</div>
          <div class="item"><img src="../../assets/img/icon_person_location.png" style="width:13px;height:13px;"/>{{userInfo.provinceName}}{{userInfo.cityName}}</div>
          <div class="item"><img src="../../assets/img/icon_job_status.png" style="width:12px;height:13px;"/>{{jobStatus}}</div>
          <div class="item"><img src="../../assets/img/icon_phone.png" style="width:7px;height:10px;"/>{{userInfo.mobile}}</div>
          <div class="item"><img src="../../assets/img/icon_mail.png" style="width:10px;height:10px;"/>{{userInfo.email}}</div>
          <div class="item"><img src="../../assets/img/icon_person_wechat.png" style="width:15px;height:13px;"/>{{userInfo.wechat}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from "@/store/user/user";
export default {
  name: "viewPersonCard",
  computed:{
    ...mapState(useUserStore,{
      userInfo(st){
        return st.userInfo.user
      },
      jobStatus(st){
        return st.curWorkStatus;
      }
    })
  },
}
</script>

<style lang="less" scoped>
  .view_person_card{
    .person_info{
      display:flex;
    }
    .photo{
      img{
        width:70px;
        height:70px;
        border-radius:70px;
      }
    }
    .person_detail{
      margin-left:20px;
      .person_item{
        display:flex;
        align-items: center;
        flex-wrap: wrap;
        width:600px;
      }
      .item{
        font-size: 14px;
        color: #1a1a1a;
        margin-right:20px;
        margin-bottom:20px;
        display:flex;
        align-items: center;
        img{
          margin-right:5px;
        }
      }
      .name{
        font-size: 24px;
        line-height:50px;
        margin-bottom:10px;
        color: #1a1a1a;
        font-weight: bold;
      }
    }
  }
</style>
