<template>
  <div class="work_card">
    <el-form
        label-position="top"
    >
      <div style=" display: flex;flex-wrap: wrap;">
        <el-form-item label="公司名称">
          <el-input v-model="work.companyName" placeholder="公司名称"/>
        </el-form-item>

        <el-form-item label="所属行业">
          <el-select v-model="work.industryClassificationId" class="m-2" placeholder="选择行业" size="large">
            <el-option
                v-for="item in industryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-input v-model="work.department" placeholder="所属部门"/>
        </el-form-item>
        <el-form-item label="职位">
<!--          <el-input v-model="work.post" placeholder="输入职位"/>-->
          <el-select v-model="work.post" class="m-2" placeholder="选择职位" size="large">
            <el-option
                v-for="item in positionList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="在职时间" class="at_position">
          <el-date-picker
              v-model="work.startDate"
              format="YYYY年M月"
              value-format="YYYY-MM"
              type="month"
              placeholder="开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="&nbsp;">
          <el-date-picker
              v-model="work.endDate"
              format="YYYY年M月"
              value-format="YYYY-MM"
              type="month"
              placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="工作内容" style="width:100%">
        <el-input
            v-model="work.content"
            rows="4"
            :autosize="{minRows:3}"
            type="textarea"
            placeholder="输入工作内容"
        />
      </el-form-item>
    </el-form>

    <div class="button_group">
      <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
      <el-button type="primary" round @click="op('save')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from "@/store/user/user";
export default {
  name: "workCard",
  props:["item"],
  data(){
    return {
      userInfo:{
        name:"",
      },
      work:{
        "companyName": "",
        "content": "",
        "department": "",
        "endDate": "",
        "industryClassificationId": "",
        "post": "",
        "startDate": ""
      },
      industryList:[],
      positionList:[]
    }
  },
  methods:{
    op(act){
      if("cancel" === act){
        this.$emit("sub_event",{act:"work_edit_cancel"});
      }else{
        if(this.work.companyName.trim() === ""){
          this.$message.error("公司名称不能为空");
          return ;
        }
        if(this.work.industryClassificationId === ""){
          this.$message.error("请选择行业");
          return ;
        }
        if(this.work.department.trim() === ""){
          this.$message.error("请填写所属部门");
          return ;
        }
        if(this.work.post.trim() === ""){
          this.$message.error("请选择职位");
          return ;
        }
        if(this.work.startDate === "" || this.work.endDate === ""){
          this.$message.error("请选择在职时间");
          return ;
        }
        if(this.work.content.trim() === ""){
          this.$message.error("请填写工作内容");
          return ;
        }
        this.$emit("sub_event",{act:"work_edit_sure",work:this.work});
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
  components:{
  },
  mounted(){
    this.industryList = this.userStore.industryList;
    this.positionList = this.userStore.positionList;
    if(this.item && Object.keys(this.item).length >0 ){
      this.work = JSON.parse(JSON.stringify(this.item))
      delete this.work.createDate;
    }
  }
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.work_card{
  padding-top:.1px;
  .at_position{
    position:relative;
    &::after{
      position:absolute;
      content:" ~ ";
      top:40px;
      right: -25px;
      color: #a1a1a1;
    }
  }
  .el-form{

  }
  .el-form-item{
    margin-right:40px;
    width:270px;
    .el-select{
      width:100%;
    }
    &:nth-child(3n){
      margin-right:0;
    }
  }
  .el-form--label-top .el-form-item__label{
    padding-bottom:0;
  }
}
</style>
