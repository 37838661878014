<template>
<div class="view_cert_card">
  <div class="view_work_hover" v-if="edit === 0">
    <div class="view_work">
      <div class="cert_name" @click="goUrl">《{{title}}》</div>
      <div class="op"><span @click="op('del')">删除</span>/<span @click="op('edit')">编辑</span></div>
    </div>
  </div>
  <upload-bar
      :title="title"
      :url="item.imageUrl"
      @sub_event="subEvent"
      upload-type="cert_image" v-else></upload-bar>
</div>
</template>

<script>
import UploadBar from "@/pages/resumeCom/uploadBar";
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
import {updateUserCertificate} from "@/api/user/user";

export default {
  name: "viewCertCard",
  components: {UploadBar},
  props:{
    title:{
      type:String,
      default:''
    },
    index:{
      type:Number,
      default:0
    },
    item:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return{
      edit:0
    }
  },
  methods:{
    goUrl(){
      window.open(this.item.imageUrl);
    },
    op(act){
      if(act === "edit"){
        this.edit = 1;
      }else if(act === "del"){
        this.$confirm(
            '确定删除该证书？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              // this.$emit("sub_event",{act:"cert_delete",index:this.index});
              this.opItem("delete");
            })
      }
    },
    opItem(act,param={}){
      let userCertificateList = JSON.parse(JSON.stringify(this.userStore.userInfo.userCertificateList));
      if(act === "delete") {
        userCertificateList.splice(this.index,1);
      }else if(act === "update"){
        userCertificateList[this.index].certificateName = param.name;
        userCertificateList[this.index].imageUrl = param.data;
      }
      updateUserCertificate(userCertificateList).then(res => {
        if(res.code === 0){
          this.userStore.$patch((state) => {
            state.userInfo.userCertificateList = userCertificateList;
          });
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
        this.edit = 0;
      });
    },
    subEvent(param){
      if(param.act === "cancel_upload"){
        this.edit = 0;
      }else if(param.act === "upload_success"){
        this.opItem("update",param);
        // param.index = this.index;
        // this.$emit("sub_event",param);
        // this.edit = 0;
      }
    }
  },
  computed:{
    ...mapStores(useUserStore)
  }
}
</script>

<style lang="less">
.view_work_hover{
  margin:0 -20px;
  border-radius:10px;
  overflow: hidden;
  .view_work{
    margin-top:10px;
    margin-bottom:10px;
    padding:0 20px;
    .cert_name{
      cursor:pointer;
    }
  }
}
.view_cert_card:hover .view_work_hover{
  background-color:#f3f2f0;
}
.view_cert_card{
  .view_work{
    display:flex;
    justify-content: space-between;
  }
  .op{
    font-size: 15px;
    color:#929292;
    span{
      color: #00b0b1;
      margin:5px;
      cursor:pointer;
    }
  }
}

</style>
