<template>
<div class="upload_bar" v-loading="loading">
  <div class="upload_title" v-if="title!=''">{{title}}</div>
  <div class="upload_wrapper">
    <el-input v-if="hasName" placeholder="输入证书名称" v-model="name"></el-input>
    <el-upload
        action=""
        :show-file-list="false"
        :http-request="handleUpload"
    >
      <el-button round type="primary">+上传</el-button>
    </el-upload>
  </div>

  <div class="button_group">
    <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
    <el-button type="primary" round @click="op('save')">保存</el-button>
  </div>
</div>
</template>

<script>
import {uploadResume,uploadImage} from "@/api/user/user";

export default {
  name: "uploadBar",
  props:{
    uploadType:{
      type:String,
      default:""
    },
    title:{
      type:String,
      default:""
    },
    hasName:{
      type:Boolean,
      default:true,
    },
    ext:{
      type:Array,
      default:new Array()
    },
    url:{
      type:String,
      default:""
    }
  },
  data(){
    return {
      name:this.title,
      loading:false,
      data:this.url
    }
  },
  methods:{
    handleUpload(file){
      if(this.uploadType === "resume"){
        this.uploadResume(file);
      }else if(this.uploadType === "cert_image"){
        this.uploadImage(file);
      }
    },
    uploadImage(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      uploadImage(formData).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          this.$message.error(res.msg);
        }else{
          this.$message({
            type:"success",
            message:"上传成功"
          })
          this.data = res.data;
          // this.userStore.getUserInfo();
          // setTimeout(()=>{
          //   this.userInfo.avatarUrl = this.userStore.userInfo.user.avatarUrl;
          // },300)
        }
      });
    },
    uploadResume(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      uploadResume(formData).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          this.$message.error(res.msg);
        }else{
          this.$message({
            type:"success",
            message:"上传成功"
          })
          this.$emit("sub_event",{act:'upload_success',type:this.uploadType,data:res.data});
          // this.userStore.getUserInfo();
          // setTimeout(()=>{
          //   this.userInfo.avatarUrl = this.userStore.userInfo.user.avatarUrl;
          // },300)
        }
      });
    },
    op(act){
      if(act === "cancel"){
        this.$emit("sub_event",{act:"cancel_upload"});
      }else{
        if(this.hasName && this.name.trim()===""){
          this.$message({
            type:"error",
            message:"输入名称"
          });
          return ;
        }
        if(this.data.trim()===""){
          this.$message({
            type:"error",
            message:"请上传图片"
          });
          return ;
        }
        this.$emit("sub_event",{act:'upload_success',type:this.uploadType,data:this.data,name:this.name});
      }
    }
  }
}
</script>

<style lang="less">
.upload_bar{
  .upload_title{
    font-size: 14px;
    color: #1a1a1a;
    margin-bottom: 8px;
    line-height: 22px;

  }
  .upload_wrapper{
    display:flex;
    align-items: center;
    .el-input{
      width:300px;
      margin-right:10px;
    }
    .el-upload-list{
      display:none;
    }
    .el-button.is-round{
      width:100px;
      padding:0;
      height:40px;
      line-height:40px;
      text-align: center;
    }
  }
}

</style>
